.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(16, 16, 16);

    background-image: url("/bgwhole6.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    animation: changeBgColor 2s infinite;

    transition: 0.3s;
  }
  

  
  
  .loadingText {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(236, 236, 236);
    font-size: 20px;
    font-family: sans-serif;
  }

  
  .logo { 
    height: 50px;
    width: 50px;
    border-radius: 60%;
    border-color: rgb(204, 204, 204);
    box-shadow: white 0px 0px 10px; 
    animation: changeBorderColor 1s infinite;

  }

  @keyframes changeBorderColor {
    30% {
        border-color: rgb(255, 179, 0); /* Initial border color at the start of the animation */
          border-radius: 60%;
  
    }
    60% {
        border-color: rgb(0, 191, 255); /* Border color halfway through the animation */
        border-radius: 60%;
  
    }
    90% {
        border-color: rgb(128, 255, 0); /* Final border color at the end of the animation */
        border-radius: 60%;
      
  
    }
  }

  @keyframes changeBgColor {
    50% {
      background-color: rgb(12, 16, 22);

  
    }
    50% {
      background-color: rgb(245, 245, 245);

  }

  }
