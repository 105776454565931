@import url('https://fonts.googleapis.com/css2?family=Cabin:ital@1&display=swap');
/*  font-family: 'Cabin', sans-serif */

@import url('https://fonts.googleapis.com/css2?family=Akronim&display=swap');
/*  font-family: 'Akronim', sans-serif; */


@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
/*  font-family: 'Teko', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
/*  font-family: 'Oxygen', sans-serif; */

.body_whole__doZ28 ul {
  list-style: none;
}
.body_whole__doZ28 ol {
  list-style: none;
}


.body_whole__doZ28 {
  display: flex;
  background: linear-gradient(to bottom, #f4f4f4f1, #f6f6f6f1, #f6f6f6);
  align-items: center;
  justify-content: center;
  width: 100vw;
  font-family: Teko, sans-serif;
  overflow-x: hidden;
}

.body_everything_wrapper__igqDl {
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;

}

.body_promo_message_first_body__yDWAq {
  margin-top: 60vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
  width: 520px;
  border-radius: 25px;
  background-color: rgba(220, 220, 220, 0.776);
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  padding: 20px;
  z-index: 90;
  gap: 10px;
  animation: body_changeBoxshadow__d__tM 5s infinite;

}

@keyframes body_changeBoxshadow__d__tM {
  30% {
    box-shadow: rgb(255, 255, 255) 0px 4px 8px;
    height: 230px;
    width: 520px;
    border-color: rgb(255, 255, 255);


  }

  60% {
    box-shadow: rgb(105, 105, 105) 0px 4px 12px;
    height: 235px;
    width: 525px;
    border-color: rgb(22, 204, 189);


  }

  90% {
    box-shadow: rgb(255, 255, 255) 0px 4px 98px;
    height: 245px;
    width: 545px;
    border-color: rgb(11, 157, 145);


  }
}

.body_promo_message_first_body__yDWAq h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  font-family: sans-serif;
  padding: 10px;
  box-shadow: white 0px 0px 7px;
  border-color: rgb(184, 184, 184);
  border-width: 1px;
  margin: 0;
  border-radius: 25px;
  height: 150px;
  width: 400px;
  animation: body_Changebgcolor__RWPoX 30s infinite;

}

@keyframes body_Changebgcolor__RWPoX {
  30% {
    background-color: rgba(18, 24, 33, 0.965);
    color: rgb(229, 229, 229);

  }

  60% {
    background-color: rgba(18, 24, 33, 0);
    color: rgb(19, 19, 19);

  }

  90% {
    background-color: rgba(18, 24, 33, 0.965);
    color: rgb(229, 229, 229);

  }
}

.body_close_button__V0Qf3 {
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
  border-radius: 0.45rem;
  box-shadow: white 0px 0px 7px;
  padding: 5px;
  transition: 0.5s;
  color: white;
  border-color: whitesmoke;
  border-width: 1px;
  animation: body_Changecolor__ArP87 20s infinite;
}

@keyframes body_Changecolor__ArP87 {
  30% {
    background-color: rgba(0, 0, 0, 0.84);
    color: white;


  }

  60% {
    background-color: rgba(48, 48, 48, 0.909);
    color: rgb(174, 174, 174);


  }

  90% {
    background-color: rgba(0, 0, 0, 0.84);
    color: white;

  }
}

.body_close_button_image__sPEgb {
  width: 10px;
  height: 10px;
}

.body_close_button__V0Qf3:hover {
  background-color: black;
  transition: 0.5s;
  transform: scale(1.05);
}

.body_promo_code__7UjwG {
  color: rgb(237, 197, 52);
}


.body_avatar_image_list_item_and_name_and_emails_item__PKcGF {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
}


.body_avatar_image_list_item__GX9gM {
  width: 70px;
  height: 70px;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  background-image: url("/me5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: white 0px 0px 1px;
  border-width: 2px;
  border-color: rgb(204, 204, 204);
  animation: body_changeBorderColor__u1m83 3s infinite, body_changeBoxShadow__3OBMB 3s infinite, body_changeBgimage__mc_yF 12s infinite;
}


.body_svg_image_deo__vQK2j {
  width: 70px;
  height: 70px;

}

@keyframes body_changeBorderColor__u1m83 {
  30% {
    border-color: rgb(255, 179, 0);
    /* Initial border color at the start of the animation */
    border-radius: 50%;

  }

  60% {
    border-color: rgb(0, 191, 255);
    /* Border color halfway through the animation */
    border-radius: 50%;

  }

  90% {
    border-color: rgb(128, 255, 0);
    /* Final border color at the end of the animation */
    border-radius: 50%;

  }
}

@keyframes body_changeBoxShadow__3OBMB {
  30% {
    box-shadow: #00fff2 0px 0px 1px;
  }

  60% {
    box-shadow: #ff0080 0px 0px 1px;
  }

  100% {
    box-shadow: #fff200 0px 0px 1px;
  }
}


@keyframes body_changeBgimage__mc_yF {
  0% {
    background-image: url("/me5.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1);


  }

  50% {
    background-image: url("/bgwhole9.jpg");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(0.9);


  }

  75% {
    background-image: url("/bgwhole12.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1.1);

  }

  100% {
    background-image: url("/bgwhole10.jpg");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1.3);

  }
}


.body_top_bg_image_wrapper__v3_c5 {
  margin-top: 60px;
  align-items: center;
  gap: 20px;
  width: 100vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-width: 1px;
  border-left: none;
  border-right: none;
  border-color: rgb(255, 255, 255);
  box-shadow: rgb(0, 0, 0) 0px 0px 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px;
  animation: body_changebgofficial__CPzqJ 15s infinite;
}

@keyframes body_changebgofficial__CPzqJ {

  0%,
  20% {
    background-image: url("/k4.jpg ");
  }

  40% {
    background-image: url("/chatappgoogledesign3.png");
  }

  60% {
    background-image: url("/coffeepls.png");
  }
  
  80% {
    background-image: url("/willcalls2.png");
  }
  100% {
    background-image: url("/been.png");
  }
}



.body_name_and_emails_item__AGsn9 {
  width: 400px;
  align-items: center;
  display: flex;
  flex-direction: column;
}


@keyframes body_textColorCycle_name__UP49K {

  0%,
  100% {
    color: rgb(255, 255, 255);
  }

  33% {
    color: rgb(213, 213, 213);
  }

  66% {
    color: rgb(35, 35, 35);
  }
}

.body_name__Gvj3C {
  font-family: 'Akronim', cursive;
  font-size: 25px;
  font-weight: 700;
  transition: color 0.5s;
  animation: body_textColorCycle_name__UP49K 6s infinite;
}

.body_name__Gvj3C:hover {
  transition: 0.3s;
  color: orange;
}

.body_emails__F1wRL {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(214, 214, 214);
  transition: 0.2s;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
  transform: scale(1.2);
}

.body_emails__F1wRL:hover {
  color: rgb(69, 69, 69);
  transition: 0.2s;
  font-weight: 700;
}

.body_phone_number__kDCHI {
  font-weight: 800;
  color: rgb(255, 166, 0);
  font-family: 'Oxygen', sans-serif;
}

.body_phone_number__kDCHI:hover {
  font-weight: 800;
  color: rgb(255, 255, 255);
}

.body_separatorLine__VOHEj {
  height: 1px;
  background-color: #ccc;
}

.body_credentials__2xh5g {

  width: -moz-max-content;

  width: max-content;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  gap: 20px;
}


.body_credentials_image__hMVSC {
  border-radius: 50px;
  width: 30px;
  height: 30px;
  transition: 0.5s;
  box-shadow: 0px 0px 15px #ffffff;
}

.body_credentials_image__hMVSC:hover {
  box-shadow: none;
  transition: 0.5s;
  transform: scale(1.2);
}

.body_top_main_header_and_paragraphs__cdggw {
  box-shadow: rgb(217, 217, 217) 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.4s;
  width: 100vw;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.body_top_main_header_and_paragraphs__cdggw:hover {
  transition: 0.4s;
  box-shadow: rgb(211, 211, 211) 0px 0px 5px;
}

.body_top_main_header__18mWZ {
  width: 200px;
  height: 100px;
  gap: 10px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body_top_main_header_header1__nq6s6 {
  white-space: nowrap;
  color: rgb(37, 37, 37);
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 700;
  margin-top: 20px;
}

.body_top_main_header_header2__nQR2Z {
  white-space: nowrap;
  color: rgb(97, 142, 164);
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;

}


.body_top_main_header_button__opAsf {
  color: rgb(67, 67, 67);
  text-align: center;
  background-color: rgb(240, 240, 240);
  border-color: rgb(46, 46, 46);
  border-width: 1px;
  width: 100px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0.95rem;
  transition: 0.4s;
}

.body_top_main_header_button__opAsf:hover {
  background-color: rgb(14, 25, 32);
  color: rgb(230, 230, 230);
  border-color: none;
  transition: 0.4s;
}


.body_top_main_paragraphs__dFFFO {
align-items: center;
justify-content: center;
width: -moz-max-content;
width: max-content;
  display: grid;
  row-gap: 30px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(4, 1fr);

}


.body_top_main_paragraphs_item__iA6tp {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 180px;
  background-color: rgb(235, 235, 235);
  background-image: url(/bgwhole7.jpg);
  background-blend-mode: darken;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  transition: 0.1s;
  border-color:  rgb(29, 29, 29);
  border-width: 2px;
  align-items: center;
  justify-content: center;
  width: 300px;
}

.body_top_main_paragraphs_item__iA6tp:nth-child(2) { 
  background-image: url(/bgwhole18.jpg);
}
.body_top_main_paragraphs_item__iA6tp:nth-child(3) { 
  background-image: url(/bgwhole15.jpg);
}
.body_top_main_paragraphs_item__iA6tp:nth-child(4) { 
  background-image: url(/bgwhole16.jpg);
}

.body_top_main_paragraphs_item__iA6tp:hover {
  background-color: rgba(0, 0, 0, 0.066);
  transition: 0.1s;
}


.body_pricing_headers_list__0jZWn { 
  width: 95vw;
  gap: 5px;
}

.body_pricing_headers_list__0jZWn p {
  font-size: 9px;
}


.body_top_main_icons_target__F9Xb_ {
  height: 30px;
  width: 30px;
  margin-top: 5px;
  transform: scale(0.6);
  transition: 0.4s;

}

.body_top_main_icons_chip__o3ERN {
  margin-top: 5px;
  width: 30px;
  height: 30px;
  transform: scale(0.4);
  transition: 0.4s;
}

.body_top_main_icons_mountain__PNLg3 {
  margin-top: 5px;
  height: 30px;
  width: 70px;
  transform: scale(0.5);
  transition: 0.4s;
}


.body_top_main_icons_features__k1vUt {
  height: 30px;
  margin-top: 5px;
  width: 30px;
  transform: scale(0.5);
  transition: 0.4s;
}



.body_top_main_icons_target__F9Xb_:hover,
.body_top_main_icons_chip__o3ERN:hover,
.body_top_main_icons_mountain__PNLg3:hover,
.body_top_main_icons_features__k1vUt:hover {
  transform: scale(0.7);
  transition: 0.4s;
}

.body_contact_page_link2__Fp5j_ {
  color: rgb(0, 220, 84);
  transition: 0.6s;
  padding: 6px;
  background-color: rgb(236, 236, 236);
  border-radius: 15px;
  margin: 3px;
  text-decoration: underline;
}

.body_contact_page_link2__Fp5j_:hover { 
  color: rgb(0, 220, 84);
background-color: rgb(30, 30, 30);
padding: 8px;
border-radius: 15px;
border:1px;
border-color:  rgb(102, 102, 102);
}

.body_contact_page_link__ssjE_ {
  color: rgb(0, 150, 150);
  transition: 0.6s;
}

.body_contact_page_link__ssjE_:hover {
  color: rgb(0, 74, 74);
  transition: 0.6s;
}

.body_lists__qNWFL {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  width: 90vw;
  box-shadow: rgba(30, 30, 30, 0.756)  -5px 15px 9px  -15px;
  transition: 0.8s;
  padding: 10px;
  border-left: none;
  border-right: none;
  border-width: 1px;
  border-color: rgb(0, 189, 214);

}

.body_lists__qNWFL:hover {
  box-shadow: rgba(47, 47, 47, 0.872)  -5px 20px 9px  -15px;
  background-color: rgb(255, 255, 255);
  transition: 0.8s;
  transform: scale(1.02);
}


.body_list3__JQ9oq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}

.body_title__YQoc9 {
  display: flex;
  flex-direction: column;
  color: rgb(71, 71, 71);
  font-size: 14px;
  font-family: Teko, sans-serif;
  font-weight: 900;
  white-space: nowrap;
}


.body_description_list_element__asGFI {
  text-align: center;
  font-size: 10px;
  padding: 10px;
  margin-top: 10px;
  color: rgb(0, 57, 63);
}



.body_top_main_header_paragraph1__RfO66 {
  text-align: left;
  height: 20px;
  margin: 5px;
  font-size: 11px;
  font-weight: 700;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(230, 230, 230);
  width: 400px;
}


.body_top_main_header_paragraph2__MJLLy {
  text-align: left;
  font-size: 10px;
  font-weight: 700;
  background-color:  rgb(31, 31, 31);
  height: 110px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgba(226, 226, 226, 0.927);
  padding: 10px;
  transition: 0.5s;
  
}

.body_top_main_header_paragraph2__MJLLy:hover {
  color: rgb(29, 29, 29);
  background-color: rgba(226, 226, 226, 0.927);
transition: 0.5s;

} 

.body_toggle_button__9s5bN {
  color: teal;
}

.body_popup__w_2u4 {
  display: none;
  z-index: 100;
  position: absolute;
  background-color: rgb(241, 241, 241);
  border-width: 1px;
  font-size: 12px;
  color: rgb(62, 62, 62);
  border-radius: 0.45rem;
  padding: 5px;
  max-width: 500px;
  margin-top: -40px;
  box-shadow: 0px 0px 10px rgba(182, 178, 178, 0.3);
}

.body_button_toggle_close_paragraph__5Pnf1 {
  color: rgb(0, 162, 180);
  font-weight: 700;
  font-size: 12px;
}




.body_essentials_list__GqyYa,
.body_essentials_list2__yMB1X {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.body_essentials_list_item__x4b8b {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.body_essentials_list_item_wrapper__ZcxlV {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes body_textColorCycle_essentials_list__TW_gZ {

  0%,
  100% {
    color: rgb(251, 251, 251);
  }

  33% {
    color: rgb(227, 227, 227);
  }

  66% {
    color: rgb(255, 255, 255);
  }
}

.body_link__zeUyz {
  color: rgb(206, 155, 0);
  text-decoration: underline;
}

.body_link__zeUyz:hover {

  text-decoration: none;

}

.body_tabber__apefX {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: -moz-max-content;
  height: max-content;
  margin-top: 30px;
  margin-bottom: 30px;
  background: linear-gradient(to top, #f6f6f6, #d1d1d1, #e8e8e8, #f6f6f6);

}

.body_tabber_wrapper__YJ0d1 {
  border-width: 1px;
  border-color: rgb(228, 228, 228);
  box-shadow: rgb(81, 81, 81) 0px 0px 10px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 35px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 10px;
  background-image: url("/bgwhole20.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  transform: scale(0.8);
}

.body_tabber_wrapper__YJ0d1:hover {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 35px;
  transition: 0.5s;
  transform: scale(0.79);
  box-shadow: rgb(9, 9, 9) 0px 0px 15px;
}

.body_description_item__RJ7Dw {
  padding: 20px;
}

.body_description__Rg948 {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(255, 255, 255);
  transition: 0.7s;
}

.body_description__Rg948:hover {
  transform: scale(1.02);
  transition: 0.7s;
  color: rgb(218, 218, 218);
}



.body_bio_tag__Od3bB {
  display: flex;
  flex-direction: row;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  font-weight: 800;
  color: rgb(212, 212, 212);
  padding: 10px;
}

.body_bio_side_tag__GgjTM {
  width: 100px;
}

.body_bio_item__L4UMD {
  display: flex;
  flex-direction: column;
  background-color: rgba(228, 228, 228, 0.071);
  padding: 10px;
  border-radius: 0.15rem;
  box-shadow: rgba(50, 50, 50, 0.52) 0px 0px 10px;
  transition: 0.5s;
}

.body_bio_item__L4UMD:hover {
  transition: 0.5s;
  transform: scale(1.005);
  padding: 10px;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.873) 0px 0px 10px;
}

.body_bio_item__L4UMD span {
  background-color: rgba(98, 98, 98, 0.307);
  border-radius: 0.75rem;
  margin-left: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: rgb(190, 190, 190);
  transition: 0.2s;
}

.body_bio_item__L4UMD span:hover {
  background-color: rgb(33, 33, 33);
  color: rgb(255, 255, 255);
  transition: 0.2s;
}

.body_bio_header__FERX7 {
  font-size: 30px;
  font-family: 'Teko', sans-serif;
  color: rgb(63, 63, 63);
  transition: 0.4s;
}

.body_bio_header__FERX7:hover {
  transition: 0.4s;
  text-decoration: underline;
}

.body_bio_header2__YzgPX {
  font-size: 20px;
  font-family: 'Teko', sans-serif;
  color: rgb(35, 35, 35);
  transition: 0.4s;
}

.body_thank_you__C0WTn {
  width: -moz-min-content;
  width: min-content;
  font-size: 10px;
  font-weight: 700;
  background-color: rgb(226, 208, 0);
  text-decoration: underline;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.322);
  border-radius: 45px;
  color: rgb(42, 42, 42);
  white-space: nowrap;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 20px;
  transition: 0.4s;
}

.body_thank_you__C0WTn:hover {
  transition: 0.4s;
  transform: scale(0.8);
}


.body_tabber_buttons_list__AC_I7 {
  max-width: -moz-max-content;
  max-width: max-content;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.body_services_more_button__zC7c1,
.body_prices_more_button__y4e40,
.body_contact_more_button__IkYwB,
.body_projects_more_button__XFvFo,
.body_more_more_button__Ltu8j {
  border-radius: 0.55rem;
  font-size: 10px;
  font-weight: 600;
  color: rgb(233, 233, 233);
  background: linear-gradient(to top, #0c0e14, #000002, #232323f1);
  padding: 10px 20px;
  cursor: pointer;
  width: 90px;
  box-shadow: rgb(137, 137, 137) 0px 0px 8px;
  transition: 0.5s;
  border-color: rgba(255, 255, 255, 0.73);
  border-width: 1px;
}

.body_services_more_button__zC7c1:hover,
.body_prices_more_button__y4e40:hover,
.body_contact_more_button__IkYwB:hover,
.body_projects_more_button__XFvFo:hover,
.body_more_more_button__Ltu8j:hover {
  transform: scale(1.05);
  transition: 0.5s;
  box-shadow: rgb(75, 75, 75) 0px 0px 7px;
  border-width: 1px;
  border-color: rgb(88, 88, 88);
  background: linear-gradient(to bottom, #191e2e12, #19213a0c);
  color: #ffffff;
}


.body_rectangle_images_wrapper__oD12y {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
}

.body_rectangle_image__gLxQb {
  border-radius: 0.75rem;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
}


.body_rectangle_image__gLxQb:hover {
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  transform: scale(1.04);
}

.body_promo_and_stuff__nZavL {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.body_promo_and_stuff_wrapper__YQTdn {
  border-width: 2px;
  border-color: rgba(191, 162, 30, 0.719);
  box-shadow: rgb(165, 165, 165) 0px 0px 10px;
  border-radius: 0.55rem;
  background-image: url("/bgwhole3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90vw;
  border: 1px solid #ccc;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10vw;
}

.body_promo_messages__kSALK {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  color: rgb(232, 232, 232);
}


.body_promo_message1__ofJT7,
.body_promo_message2__D4loK {
  color: rgb(62, 62, 62);
  border-color: white;
  padding: 5px;
  border-radius: 0.45rem;
  border-width: 1px;
  background-color: rgb(243, 243, 243);
  margin-bottom: 10px;
  transition: 0.5s;
  font-size: 12px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.body_promo_message1__ofJT7:hover,
.body_promo_message2__D4loK:hover {
  background-color: rgb(240, 240, 240);
  transition: 0.5s;
  transform: scale(1.02);
  color: rgb(27, 27, 27);
  box-shadow: white 0px 0px 10px;
  border-color: rgb(125, 125, 125);
}

.body_promo_code__7UjwG {
  font-weight: 800;
  color: #508ed0;
}

.body_and_more_stuff__w3cOM {
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  gap: 10px;
}

.body_perks_header__ZWJB2 {
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 45px;
  box-shadow: rgb(135, 135, 135) 0px 0px 5px;
  width: -moz-max-content;
  width: max-content;
  background-color: rgb(246, 246, 246);
  color: rgb(69, 69, 69);
  font-family: 'Cabin', sans-serif;
  font-size: 15px;
  margin-bottom: 0px;
  transition: 0.4s;
}

.body_perks_header__ZWJB2:hover {
  transition: 0.4s;
  transform: scale(1.03);
}

.body_offered_list__tfvTR {
  gap: 0px;
  margin-top: 0px;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.body_offered_item__Uy676 {
  transform: scale(0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0px;
  gap: 10px;
}

.body_logo_ssl_logo__MlvhL {
  margin-top: 0px;
  box-shadow: white 0px 0px 7px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  transition: 0.5s;
}

.body_logo_ssl_logo__MlvhL:hover,
.body_private_domain_logo__Z8k1o:hover {
  box-shadow: white 0px 0px 15px;
  transition: 0.5s;
  transform: scale(1.05);
}

.body_more_tag__C0LZJ {
  background-color: rgb(240, 240, 240);
  padding: 3px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 45px;
  color: rgb(77, 77, 77);
  font-size: 10px;
  font-weight: 600;
  font-family: 'Cabin', sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.4s;
  box-shadow: rgb(82, 82, 82) 0px 0px 6px;
}

.body_more_tag__C0LZJ:hover {
  transition: 0.4s;
  transform: scale(1.03);
  box-shadow: white 0px 0px 8px;
}

.body_span_divider_parent__UGM81 {
  margin-top: 15px;
  display: flex;
  position: relative;
}

.body_span_divider_wrapper__4wIS8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  padding: 0.5rem;
  gap: 2px;
}

.body_span_1__CXJkC,
.body_span_2__Noxa0,
.body_span_3__Q8GMW,
.body_span_4__eGgxi,
.body_span_5__5gryW {
  height: 3px;
  width: 100px;
}

.body_span_1__CXJkC {
  background-color: rgba(0, 123, 103, 0.769);
  box-shadow: rgba(255, 255, 255, 0.458) 0px 0px 6px;
}

.body_span_2__Noxa0 {
  background-color: rgba(255, 0, 0, 0.553);
  box-shadow: rgba(255, 255, 255, 0.458) 0px 0px 6px;
}

.body_span_3__Q8GMW {
  background-color: rgba(0, 255, 60, 0.597);
  box-shadow: rgba(255, 255, 255, 0.458) 0px 0px 6px;
}

.body_span_4__eGgxi {
  background-color: rgba(255, 230, 0, 0.859);
  box-shadow: rgba(255, 255, 255, 0.458) 0px 0px 6px;
}

.body_span_5__5gryW {
  background-color: rgba(212, 0, 255, 0.687);
  box-shadow: rgba(255, 255, 255, 0.458) 0px 0px 6px;
}


.body_compare__vRKv8 {
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.body_we_offer__VhSV6 {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.body_pricing__qfqqL {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.body_pricing_wrapper__InL40 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.body_pricing_page__4lZ3N ul {
  list-style: none;
}


.body_pricing_headers_list_wrapper__RLdvo {
  width: 100vw;
  align-items: center;
  justify-content: center;
  align-self: center;
  display: flex;
}

.body_pricing_headers_list__0jZWn {
  margin-top: 20px;
  width: 95vw;
  background-color: rgb(20, 32, 39);
  box-shadow: rgb(255, 255, 255) 0px 0px 7px;
  border-radius: 0.90rem;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.body_pricing_headers_list__0jZWn:hover {
  transition: 0.2s;
  box-shadow: rgb(255, 255, 255) 0px 0px 10px;
}

.body_pricing_headers_list__0jZWn p {
  white-space: nowrap;
  font-family: 'Oswald', sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 0.15rem;
  transition: 0.5s;
}

.body_pricing_headers_list__0jZWn p:hover {
  color: rgb(182, 182, 182);
  transition: 0.3s;
  text-decoration: underline;
}

.body_pricing_headers_list__0jZWn li:hover {
  color: rgb(255, 191, 0);
  transition: 0.3s;
  text-decoration: underline;
}

.body_we_offer_span__MBK0G {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(55, 55, 55);
}

.body_we_offer_span2__Y0Ob4 {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(52, 183, 0);
}

.body_we_offer_span3__gNwIl {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(150, 77, 0);
}

.body_promo_regular_parent__Fv7Gv {
  display: flex;
  justify-content: center;
  transform: scale(0.85);

}

.body_promo_regular_wrapper__4Go5z {
  border-color: rgba(2, 73, 0, 0.491);
  list-style: none;
  border-width: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  background-color: white;
  border-radius: 50px;
  transition: 0.6s;
  margin-bottom: 10px;
  box-shadow: rgb(220, 220, 220)0px 0px 20px;
}

.body_promo_regular_wrapper__4Go5z:hover {
  box-shadow: rgb(220, 220, 220)0px 0px 10px;
  transition: 0.6s;
}

.body_promo_price__Bdj9y,
.body_regular__B1HVy {
  transition: 0.3s;
  padding: 15px;
}

.body_promo_price__Bdj9y:hover,
.body_regular__B1HVy:hover {
  transition: 0.3s;
  transform: scale(1.05);
}

.body_pricing_note__1GXpk {
  text-align: center;
  display: flex;
  width: 30vw;
  font-size: 9px;
  color: rgb(31, 31, 31);
  margin-bottom: 40px;
}

.body_your_session_has_tim__zsbXk,
.body_your_session_has_tim2__D_BMN {
  background-color: #ffbb00;
  color: rgb(239, 239, 239);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 50px;
  white-space: nowrap;
  width: 80px;
  height: 35px;
  font-size: 10px;
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(2, 56, 0, 0.491) 3px 2px 7px;
  transition: 0.7s;
}

.body_your_session_has_tim__zsbXk.body_clickedButton__7wyOQ {
  transition: 0.6s;
  transform: scale(1.2);
  background-color: rgb(51, 180, 0);
  box-shadow: rgb(51, 180, 0) 0px 0px 8px;
  border-width: 1px;
  border-color: rgb(28, 99, 0) 0px 0px 8px;
  border-radius: 50px;
  box-shadow: rgba(2, 56, 0, 0.708) 3px 2px 7px;

}

.body_your_session_has_tim2__D_BMN.body_clickedButton__7wyOQ {
  transition: 0.6s;
  transform: scale(1.2);
  background-color: rgb(51, 180, 0);
  box-shadow: rgb(51, 180, 0) 0px 0px 8px;
  border-width: 1px;
  border-color: rgb(28, 99, 0) 0px 0px 8px;
  border-radius: 50px;
  box-shadow: rgba(2, 56, 0, 0.681) 3px 2px 7px;

}

.body_your_session_has_tim__zsbXk:hover,
.body_your_session_has_tim2__D_BMN:hover {
  transition: 0.7s;
  color: rgba(0, 0, 0, 0.541);
}

.body_pricing_tab_groups_wrapper__89odA {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.body_pricing_tab_group_1__NAKEL,
.body_pricing_tab_group_2__Y_Bkh {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.body_frame_405__UyUP3 {
  display: grid;
  gap: 30px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(3, 1fr);
}

.body_label_header__ZlZdf {
  font-size: 9px;
  color: black;
}



.body_frame_wrapper__io16d {
  z-index: 0;
  box-shadow: rgb(235, 235, 235) 0px 0px 10px 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  border-width: 2px;
  border-bottom-right-radius: 0.85rem;
  border-bottom-left-radius: 0.85rem;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  height: 600px;
  color: rgb(212, 212, 212);
  font-size: 12px;
  transition: 0.5s;
}

.body_frame_wrapper__io16d:nth-child(5) {
  margin-bottom: 100px;
}

.body_frame_wrapper__io16d:hover {
  color: rgb(255, 255, 255);
  transition: 0.5s;
  box-shadow: rgb(193, 193, 193) 0px 0px 10px 1px;
  transform: scale(1.01);
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.body_frame_402__I0idh {
  width: -moz-max-content;
  width: max-content;
  justify-content: center;
  align-items: center;
  display: flex;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  gap: 20px;

}

.body_recommended_div__auUWi {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  background: linear-gradient(to right, rgb(43, 153, 0), rgb(78, 180, 37), rgb(0, 183, 122));
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-width: 1px;
  transition: 0.6s;
  box-shadow: rgb(0, 183, 122) 0px 0px 10px;
}

.body_recommended_div__auUWi:hover {
  transform: scale(1.05);
  transition: 0.6s;
  box-shadow: rgb(0, 183, 122) 0px 0px 15px;
}


.body_your_session_has_tim3_regular__JsRqu {
  font-family: 'Teko', sans-serif;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 60px;
  width: 120px;
  border-width: 1px;
  border-color: linear-gradient(to right, rgba(255, 255, 255, 0.731), rgba(255, 255, 255, 0.935), rgba(255, 255, 255, 0.752));
  font-size: 20px;
  font-weight: 400;
  padding: 1px;
  color: rgb(70, 70, 70);
  box-shadow: white 0px 0px 8px;
  transition: 0.6s;
  background-color: rgba(255, 255, 255, 0.981);
  background: linear-gradient(to right, rgba(184, 184, 184, 0.801), rgba(255, 255, 255, 0.935), rgba(255, 255, 255, 0.845));
  padding-top: 5px;
}

.body_your_session_has_tim3_promo__pKMay {
  font-family: 'Teko', sans-serif;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 60px;
  border-width: 1px;
  width: 120px;
  font-size: 20px;
  font-weight: 400;
  padding: 1px;
  color: rgb(70, 70, 70);
  box-shadow: white 0px 0px 8px;
  transition: 0.6s;
  background-color: rgba(255, 255, 255, 0.981);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.845), rgba(255, 255, 255, 0.935), rgba(179, 179, 179, 0.801));
  padding-top: 5px;
}



.body_your_session_has_tim3_regular__JsRqu:hover,
.body_your_session_has_tim3_promo__pKMay:hover {
  transition: 0.6s;
  transform: scale(1.03);
  color: rgb(30, 30, 30);
  box-shadow: white 0px 0px 10px;
}

.body_plan_description__MBenD {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  font-size: 7px;
  color: rgb(32, 32, 32);
  width: 200px;
}

.body_sites_list__o_tGP {
  position: relative;
}

.body_list_component__j_Lfk {
  display: flex;
}

.body_sites_list__o_tGP li {
  align-items: center;
  font-family: 'Cabin', sans-serif;
  color: rgb(109, 109, 109);
  font-weight: 700;
  margin: 5px;
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 0.85rem;
  transition: 0.7s;
  gap: 10px;
}

.body_sites_list__o_tGP li:hover {
  font-size: 11px;
  font-weight: 700;
  border-radius: 0.85rem;
  transition: 0.7s;
  transform: scale(1.1);
  box-shadow: white 0px 0px 10px;
  background: linear-gradient(rgb(255, 255, 255), rgb(229, 229, 229));
  color: rgb(40, 40, 40);
}

.body_list_title__Fud14 {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 11px;
  color: orange;
}

.body_list_description__0S2z3 {
  font-size: 9px;
  white-space: nowrap;
}

.body_regular_disclaimer___AYIx {
  border-width: 0.5px;
  background-color: red;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0.45rem;
  font-family: 'Cabin', sans-serif;
  font-size: 10px;
  font-weight: 400;
}

.body_percent_disclaimer__9WXyw {
  height: -moz-max-content;
  height: max-content;
  border-width: 0.5px;
  background-color: rgb(255, 255, 255);
  color: rgb(255, 0, 0);
  width: -moz-max-content;
  width: max-content;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0.45rem;
  font-family: 'Cabin', sans-serif;
  font-size: 10px;
  font-weight: 400;
}

.body_frame_399__0WwM6 {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body_your_session_has_tim5__qDMof {
  text-decoration: overline;
}

.body_your_session_has_tim5__qDMof:hover {
  text-decoration: underline;
}

.body_frame_407__vWP99 {
  gap: 2px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin-bottom: 5px;
}

.body_your_session_has_tim5__qDMof {
  font-size: 25px;
  color: rgb(0, 189, 0);
}

.body_component_6__pO66G {
  background: linear-gradient(to right, rgb(113, 130, 0), rgb(0, 214, 89));
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: -moz-max-content;
  width: max-content;
  box-shadow: white 0px 0px 5px;
  transition: 0.6s;
  border-width: 1px;
}

.body_component_6__pO66G:hover {
  box-shadow: white 0px 0px 8px;
  transform: scale(1.04);
  transition: 0.6s;
}




@media (min-width: 200px) and (max-width: 300px) and (orientation: portrait) {

  .body_promo_message_first_body__yDWAq {
    transform: scale(0.3);
  }

  .body_credentials__2xh5g {
    transform: scale(0.5);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_name__Gvj3C {
    font-size: 30px;
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
  }


  
  .body_pricing_headers_list__0jZWn { 
    width: 95vw;
    gap: 5px;
  }

  .body_pricing_headers_list__0jZWn p {
    font-size: 9px;
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.45);
  }


  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }



  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }



  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 85vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}


@media (min-width: 200px) and (max-width: 300px) and (orientation: landscape) {

  .body_promo_message_first_body__yDWAq {
    transform: scale(0.3);
  }

  .body_credentials__2xh5g {
    transform: scale(0.5);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_avatar_image_deo__wWIJj {
    margin-top: -140px;
    margin-bottom: 150px;
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
  }

  
  .body_pricing_headers_list__0jZWn { 
    width: 95vw;
    gap: 5px;
  }

  .body_pricing_headers_list__0jZWn p {
    font-size: 9px;
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.45);
  }


  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 85vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}




@media (min-width: 300px) and (max-width: 400px) and (orientation: portrait) {


  .body_promo_message_first_body__yDWAq {
    transform: scale(0.5);
  }

  .body_credentials__2xh5g {
    transform: scale(0.6);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_name__Gvj3C {
    font-size: 30px;
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
  }

  .body_pricing_headers_list__0jZWn { 
    width: 95vw;
    gap: 5px;
  }

  .body_pricing_headers_list__0jZWn p {
    font-size: 9px;
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.6);
  }



  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 85vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}



@media (min-width: 300px) and (max-width: 400px) and (orientation: landscape) {

  .body_promo_message_first_body__yDWAq {
    transform: scale(0.5);
  }

  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 25px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }



  .body_name__Gvj3C {
    font-size: 30px;
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }



  .body_pricing_headers_list__0jZWn { 
    width: 95vw;
    gap: 5px;
  }

  .body_pricing_headers_list__0jZWn p {
    font-size: 9px;
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.6);
  }


  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}




@media (min-width: 400px) and (max-width: 500px) and (orientation: portrait) {

  .body_promo_message_first_body__yDWAq {
    transform: scale(0.7);
  }

  .body_credentials__2xh5g {
    transform: scale(0.5);
    gap: 45px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }



  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.75);
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 60vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}


@media (min-width: 400px) and (max-width: 500px) and (orientation: landscape) {

  .body_promo_message_first_body__yDWAq {
    transform: scale(0.7);
  }

  .body_credentials__2xh5g {
    transform: scale(0.5);
    gap: 45px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.75);
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 60vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}





@media (min-width: 500px) and (max-width: 600px) and (orientation: portrait) {

  .body_promo_message_first_body__yDWAq {
    transform: scale(0.7);
  }

  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 45px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.95);
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }

  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}




@media (min-width: 500px) and (max-width: 600px) and (orientation: landscape) {


  .body_promo_message_first_body__yDWAq {
    transform: scale(0.7);
  }

  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 45px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_tabber_buttons_list__AC_I7 {
    transform: scale(0.95);
  }



  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_lists__qNWFL {
    flex-direction: column;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}



@media (min-width: 600px) and (max-width: 700px) and (orientation: portrait) {



  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 50px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}


@media (min-width: 600px) and (max-width: 700px) and (orientation: landscape) {


  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 50px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }



  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}



@media (min-width: 700px) and (max-width: 800px) and (orientation: portrait) {

  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 50px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }

  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 210px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}



@media (min-width: 700px) and (max-width: 800px) and (orientation: landscape) {

  .body_credentials__2xh5g {
    transform: scale(0.7);
    gap: 50px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_name__Gvj3C {
    font-size: 30px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_top_main_header_and_paragraphs__cdggw {
    display: flex;
    flex-direction: column;
  }

  .body_top_main_paragraphs__dFFFO {
    display: grid;
    row-gap: 15px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }



  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }



  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 210px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 170px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {
    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;
  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}




@media (min-width: 800px) and (max-width: 900px) and (orientation: portrait) {

  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }


  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    justify-content: center;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}


@media (min-width: 800px) and (max-width: 900px) and (orientation: landscape) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_bio_item__L4UMD {
    display: none;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    justify-content: center;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }
}


@media (min-width: 900px) and (max-width: 1000px) and (orientation: portrait) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }



  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 290px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 900px) and (max-width: 1000px) and (orientation: landscape) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 290px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}







@media (min-width: 1000px) and (max-width: 1100px) and (orientation: portrait) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }




  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }

  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}





@media (min-width: 1000px) and (max-width: 1100px) and (orientation: landscape) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }



  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }

  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}



@media (min-width: 1100px) and (max-width: 1200px) and (orientation: portrait) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }


  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}



@media (min-width: 1100px) and (max-width: 1200px) and (orientation: landscape) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }

  .body_avatar_image__rRuqZ {
    display: none;
  }

  .body_avatar_image_list_item_paragraph__QL2zF {
    display: none;
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 1200px) and (max-width: 1300px) and (orientation: portrait) {



  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.6);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }



  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 1200px) and (max-width: 1300px) and (orientation: landscape) {



  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.6);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }

  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 1300px) and (max-width: 1400px) and (orientation: portrait) {



  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    margin-top: 0;
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }

  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: -moz-max-content;
    width: max-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    flex-direction: column;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}



@media (min-width: 1300px) and (max-width: 1400px) and (orientation: landscape) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 0;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    margin-top: 0;
    transform: scale(0.7);
  }



  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }

  .body_tabber_wrapper__YJ0d1 {
    width: 95vw;
    flex-direction: column;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }


  .body_rectangle_images_wrapper__oD12y {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: -moz-max-content;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .body_rectangle_image__gLxQb {

    -o-object-fit: cover;

       object-fit: cover;
    border-radius: 0.75rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.859) 0px 0px 6px;
  }


  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: -moz-max-content;
    width: max-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    flex-direction: column;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 1400px) and (max-width: 1500px) and (orientation: portrait) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    margin-top: 0;
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 80vw;
    flex-direction: column;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    flex-direction: column;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 1400px) and (max-width: 1500px) and (orientation: landscape) {



  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    margin-top: 0;
    transform: scale(0.7);
  }

  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 80vw;
    flex-direction: column;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }



  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    flex-direction: column;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}



@media (min-width: 1500px) and (max-width: 1600px) and (orientation: portrait) {



  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.7);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    margin-top: 0;
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 80vw;
    flex-direction: column;
  }


  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }




  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    flex-direction: column;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}




@media (min-width: 1500px) and (max-width: 1600px) and (orientation: landscape) {


  .body_everything_wrapper__igqDl {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .body_credentials__2xh5g {
    width: 100vw;
    transform: scale(0.6);
    gap: 20px;
    margin-bottom: 0;
  }

  .body_name_and_emails_item__AGsn9 {
    transform: scale(0.7);
  }


  .body_offered_item__Uy676 {
    flex-direction: column;
    gap: 5px;
  }


  .body_tabber_wrapper__YJ0d1 {
    width: 80vw;
    flex-direction: column;
  }

  .body_thank_you__C0WTn {
    transform: scale(0.7);
  }

  .body_table_item__wjcVg {
    white-space: nowrap;
  }





  .body_promo_message1__ofJT7,
  .body_promo_message2__D4loK {
    font-size: 10px;
    font-weight: 600;
  }

  .body_promo_and_stuff_wrapper__YQTdn {
    flex-direction: column;
    gap: 0;
  }

  .body_and_more_stuff__w3cOM {
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .body_offered_item__Uy676 {
    transform: scale(0.6);
    flex-direction: column;
    gap: 0px;
  }

  .body_compare__vRKv8 {
    margin-top: 50px;
    transform: scale(0.7);
  }

  .body_more_tag__C0LZJ {
    margin-top: -5px;
  }

  .body_pricing_tab_groups_wrapper__89odA {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .body_frame_wrapper__io16d {
    padding: 20px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .body_frame_405__UyUP3 {
    width: -moz-max-content;
    width: max-content;
    display: grid;
    flex-direction: column;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }


  .body_label__qSLF_ {
    width: 100px;
  }

  .body_frame_402__I0idh {
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 0px;
  }

  .body_your_session_has_tim3_regular__JsRqu,
  .body_your_session_has_tim3_promo__pKMay {
    width: 250px;
  }

  .body_sites_list__o_tGP {
    position: relative;
  }

  .body_list_component__j_Lfk {

    display: flex;
  }

  .body_sites_list__o_tGP li {
    padding: 0;

  }

  .body_list_title__Fud14 {
    font-size: 9px;
    color: orange;
  }

  .body_list_description__0S2z3 {
    font-size: 8px;
    white-space: nowrap;
  }

}

.loading_loadingScreen__NGFDL {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(16, 16, 16);

    background-image: url("/bgwhole6.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    animation: loading_changeBgColor__qgm7K 2s infinite;

    transition: 0.3s;
  }
  

  
  
  .loading_loadingText___4xHB {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(236, 236, 236);
    font-size: 20px;
    font-family: sans-serif;
  }

  
  .loading_logo__NL73i { 
    height: 50px;
    width: 50px;
    border-radius: 60%;
    border-color: rgb(204, 204, 204);
    box-shadow: white 0px 0px 10px; 
    animation: loading_changeBorderColor__cGCIy 1s infinite;

  }

  @keyframes loading_changeBorderColor__cGCIy {
    30% {
        border-color: rgb(255, 179, 0); /* Initial border color at the start of the animation */
          border-radius: 60%;
  
    }
    60% {
        border-color: rgb(0, 191, 255); /* Border color halfway through the animation */
        border-radius: 60%;
  
    }
    90% {
        border-color: rgb(128, 255, 0); /* Final border color at the end of the animation */
        border-radius: 60%;
      
  
    }
  }

  @keyframes loading_changeBgColor__qgm7K {
    50% {
      background-color: rgb(12, 16, 22);
      background-color: rgb(245, 245, 245);

  
    }

  }

